import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

/**
 * This service tracks the current width of the viewport to make changes to the layout.
 */
@Injectable({
  providedIn: 'root'
})
export class B2bHeaderService {

  private renderer: Renderer2;
  private isMobileSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.checkScreenWidth();
    this.addResizeListener();
  }

  private checkScreenWidth(): void {
    const isMobile = window.innerWidth < 992;
    this.isMobileSubject.next(isMobile);
  }

  private addResizeListener(): void {
    this.renderer.listen('window', 'resize', () => {
      this.checkScreenWidth();
    });
  }

  /**
   * Public observable which listens to layout changes. 
   * Emits `true` when viewport width is < 992px, otherwise `false`
   * @returns 
   */
  getIsMobile(): Observable<boolean> {
    return this.isMobileSubject.asObservable().pipe(
      distinctUntilChanged()
    );
  }
}
